





































































































































import {Component, Vue} from 'vue-property-decorator';
import {
    dispatchCreateCast,
} from '@/store/cast/actions';
import {
    dispatchUploadImagesCast,
} from '@/store/imagesCast/actions';
import {
    dispatchGetCastTypes,
} from '@/store/castType/actions';
import {
    readCastTypes,
} from '@/store/castType/getters';
import {dispatchGetBloods} from '@/store/blood/actions';
import {dispatchGetClientUsers} from '@/store/client/actions';
import {readBloods} from '@/store/blood/getters';
import {readRanks} from '@/store/rank/getters';
import {dispatchGetRanks} from '@/store/rank/actions';
import {readClientUsers} from '@/store/client/getters';
import {
    ICastCreate, ICastTypes, IClientUserProfile,
} from '@/interfaces';


@Component({
    components: {},
})
export default class CreateCast extends Vue {
    public valid = false;
    public name: string = '';
    public rankId: number = 0;
    public isActive: boolean = false;
    public occupation?: string = '';
    public place?: string = '';
    public birthplace?: string = '';
    public bloodTypeId?: number = 5;
    public blockClientUsers: IClientUserProfile[] = [];
    public fCastTypes: ICastTypes[] = [];
    public birthDate?: string = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
    public height?: number = 0;
    public weight?: number = 0;
    public bwhMeasurements?: string = '';
    public profile?: string = '';
    public favoriteFood?: string = '';
    public hateFood?: string = '';
    public hobby?: string = '';
    public specialty?: string = '';
    public skills?: string = '';
    public foreignLanguages?: string = '';
    public golf?: string = '';
    public alcohol?: string = '';
    public cigarette?: string = '';
    public askingPrice?: number = 0;
    public goodTalker?: string = '';
    public desiredAgeRange?: string = '';

    public fileName: string[] = [];
    public url: string = '';
    public imageUrl: string[] = [];
    public fileObject: any = null;

    get castTypes() {
        return readCastTypes(this.$store);
    }

    get ranks() {
        return readRanks(this.$store);
    }

    get bloods() {
        return readBloods(this.$store);
    }

    get clientUsers() {
        return readClientUsers(this.$store);
    }

    get age() {
        const date = new Date(this.birthDate ?? '');
        const current = new Date();
        return (current.getFullYear() - date.getFullYear()) + '歳';
    }

    public async mounted() {
        await dispatchGetBloods(this.$store);
        await dispatchGetCastTypes(this.$store);
        await dispatchGetRanks(this.$store);
        await dispatchGetClientUsers(this.$store);
    }

    public cancel() {
        this.$router.back();
    }

    public reset() {
        this.name = '';
        this.rankId = 0;
        this.isActive = false;
        this.occupation = '';
        this.place = '';
        this.birthplace = '';
        this.bloodTypeId = 5;
        this.blockClientUsers = [];
        this.fCastTypes = [];
        this.birthDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
        this.height = 0;
        this.weight = 0;
        this.bwhMeasurements = '';
        this.profile = '';
        this.favoriteFood = '';
        this.hateFood = '';
        this.hobby = '';
        this.specialty = '';
        this.skills = '';
        this.foreignLanguages = '';
        this.golf = '';
        this.alcohol = '';
        this.cigarette = '';
        this.askingPrice = 0;
        this.goodTalker = '';
        this.desiredAgeRange = '';

        this.$validator.reset();
    }

    public onFilePicked(event) {
        this.imageUrl = [];
        this.fileObject = [];
        this.fileName = [];
        const files = event.target.files;
        const fileUpload: any = this.$refs.fileInput;
        for (let i = 0; i < fileUpload.files.length; i++) {
            this.fileName.push(files[i].name);
            const previewImage = URL.createObjectURL(files[i]);
            this.imageUrl.push(previewImage);
        }
        this.fileObject = fileUpload;
    }

    public onPickFile() {
        const elem: any = this.$refs.fileInput;
        elem.click();
    }

    public removeImage(index) {
        this.imageUrl.splice(index, 1);
        this.fileName.splice(index, 1);
        const dt = new DataTransfer();
        const {files} = this.fileObject;
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (index !== i) { dt.items.add(file); }
            this.fileObject.files = dt.files;
        }
    }

    public async submit() {
        if (await this.$validator.validateAll()) {
            const createCast: ICastCreate = {
                name: this.name,
                rank_id: this.rankId,
                is_active: this.isActive,
                occupation: this.occupation,
                blood_type_id: this.bloodTypeId,
                cast_types: this.fCastTypes,
                block_client_users: this.blockClientUsers,
                birth_date: this.birthDate,
                height: this.height,
                weight: this.weight,
                bwh_measurements: this.bwhMeasurements,
                profile: this.profile,
                favorite_food: this.favoriteFood,
                hate_food: this.hateFood,
                hobby: this.hobby,
                specialty: this.specialty,
                skills: this.skills,
                foreign_languages: this.foreignLanguages,
                golf: this.golf,
                alcohol: this.alcohol,
                cigarette: this.cigarette,
                place: this.place,
                birthplace: this.birthplace,
                asking_price: this.askingPrice,
                good_talker: this.goodTalker,
                desired_age_range: this.desiredAgeRange,
            };
            const response = await dispatchCreateCast(this.$store, createCast);
            if (response?.status === 200) {
                if (this.fileObject === null) {
                    await this.$router.push('/main/casts/all');
                } else {
                    const formData = new FormData();
                    for (let i = 0; i < this.fileObject.files.length; i++) {
                        const file = this.fileObject.files[i];
                        formData.append('files', file);
                        formData.append('sort_number', '' + i);
                    }
                    const upload = await dispatchUploadImagesCast(this.$store,
                        {
                            files: formData,
                            castId: response?.data.id,
                        },
                    );
                    if (upload?.status === 200) {
                        await this.$router.push('/main/casts/all');
                    }
                }
            }
        }
    }
}
