const env = process.env.VUE_APP_ENV;
const imageUrl = process.env.VUE_APP_IMAGE_URL;

let envApiUrl = '';

if (env === 'production') {
  envApiUrl = `https://${process.env.VUE_APP_DOMAIN_PROD}`;
} else if (env === 'staging') {
  envApiUrl = `https://${process.env.VUE_APP_DOMAIN_STAG}`;
} else {
  envApiUrl = `http://${process.env.VUE_APP_DOMAIN_DEV}`;
}

// TODO:一時的にデプロイ時にapiURLを直接代入する
// 後でちゃんとデプロイ対応する
// export const apiUrl = envApiUrl;
// export const apiUrl = 'https://backend-laaaisvnta-an.a.run.app'; // STG
export const apiUrl = 'https://backend-snjrgcxf7q-an.a.run.app'; // PROD
export const appName = process.env.VUE_APP_NAME;
export const hostImageUrl = imageUrl;
