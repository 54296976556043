import { IClientUserProfile } from '@/interfaces';
import { ClientState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setClientUsers(state: ClientState, payload: IClientUserProfile[]) {
        state.users = payload;
    },
    setClientUser(state: ClientState, payload: IClientUserProfile) {
        const users = state.users.filter((user: IClientUserProfile) => user.id !== payload.id);
        users.push(payload);
        state.users = users;
    },
};

const { commit } = getStoreAccessors<ClientState, State>('');

export const commitSetClientUser = commit(mutations.setClientUser);
export const commitSetClientUsers = commit(mutations.setClientUsers);
