import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { ImageCastState } from './state';

const defaultState: ImageCastState = {
  uploadImagesCast: [],
  imagesCast: [],
};

export const uploadImageCastModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
