import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { CastState } from './state';

const defaultState: CastState = {
  cast: [],
};

export const castModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
