import { ClientState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    clientUsers: (state: ClientState) => state.users,
    clientOneUser: (state: ClientState) => (userId: number) => {
        const filteredUsers = state.users.filter((user) => user.id === userId);
        if (filteredUsers.length > 0) {
            return { ...filteredUsers[0] };
        }
    },
};

const { read } = getStoreAccessors<ClientState, State>('');

export const readClientOneUser = read(getters.clientOneUser);
export const readClientUsers = read(getters.clientUsers);
