import { render, staticRenderFns } from "./CastDetail.vue?vue&type=template&id=180da0d0&scoped=true&"
import script from "./CastDetail.vue?vue&type=script&lang=ts&"
export * from "./CastDetail.vue?vue&type=script&lang=ts&"
import style0 from "./CastDetail.vue?vue&type=style&index=0&id=180da0d0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "180da0d0",
  null
  
)

export default component.exports