import { api } from '@/api';
import { ActionContext } from 'vuex';
import { ICastCreate, ICastUpdate } from '@/interfaces';
import { State } from '../state';
import { CastState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import {
    commitSetCasts,
} from './mutations';
import { dispatchCheckApiError } from '../main/actions';

type MainContext = ActionContext<CastState, State>;

export const actions = {
    async actionGetCasts(context: MainContext) {
        try {
            const response = await api.getCasts(context.rootState.main.token);
            if (response) {
                commitSetCasts(context, response.data);
            }
        } catch (error) {
            return error;
        }
    },
    async actionCreateCast(context: MainContext, payload: ICastCreate) {
        try {

            const response = (await Promise.all([
                api.createCast(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            return response;
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateCast(context: MainContext, payload: { id: number, cast: ICastUpdate }) {
        try {
            const response = (await Promise.all([
                api.updateCast(context.rootState.main.token, payload.id, payload.cast),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            return response;
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionDeleteCast(context: MainContext, payload: { id: number}) {
        try {
            const response = await api.deleteCast(context.rootState.main.token, payload.id);
            if (response) {
                return response;
            }
        } catch (error) {
            return error;
        }
    },
};

const { dispatch } = getStoreAccessors<CastState, State>('');

export const dispatchGetCasts = dispatch(actions.actionGetCasts);
export const dispatchCreateCast = dispatch(actions.actionCreateCast);
export const dispatchUpdateCast = dispatch(actions.actionUpdateCast);
export const dispatchDeleteCast = dispatch(actions.actionDeleteCast);
