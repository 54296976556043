























































































































































import {Component, Vue} from 'vue-property-decorator';
import {hostImageUrl} from '@/env';
import {
    readCast,
} from '@/store/cast/getters';
import {readCastType, readCastTypes} from '@/store/castType/getters';
import {
    dispatchGetCasts,
    dispatchUpdateCast,
} from '@/store/cast/actions';
import {dispatchGetCastType, dispatchGetCastTypes} from '@/store/castType/actions';
import {dispatchGetBloods} from '@/store/blood/actions';
import {dispatchGetClientUsers} from '@/store/client/actions';
import {readBloods} from '@/store/blood/getters';
import {ICastUpdate, IClientUserProfile, ICastTypes} from '@/interfaces';
import {readRanks} from '@/store/rank/getters';
import {readImagesCast} from '@/store/imagesCast/getters';
import {readClientUsers} from '@/store/client/getters';
import {dispatchGetRanks} from '@/store/rank/actions';
import {dispatchGetImagesCast, dispatchDeleteImageCast, dispatchUploadImagesCast} from '@/store/imagesCast/actions';

@Component({
    components: {},
})

export default class EditCast extends Vue {
    public valid = false;
    public name: string = '';
    public rankId: number = 0;
    public isActive: boolean = false;
    public occupation?: string = '';
    public place?: string = '';
    public birthplace?: string = '';
    public bloodTypeId?: number = 5;
    public blockClientUsers: IClientUserProfile[] = [];
    public fCastTypes: ICastTypes[] = [];
    public birthDate?: string = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
    public height?: number = 0;
    public weight?: number = 0;
    public bwhMeasurements?: string = '';
    public profile?: string = '';
    public favoriteFood?: string = '';
    public hateFood?: string = '';
    public hobby?: string = '';
    public specialty?: string = '';
    public skills?: string = '';
    public foreignLanguages?: string = '';
    public golf?: string = '';
    public alcohol?: string = '';
    public cigarette?: string = '';
    public askingPrice?: number = 0;
    public goodTalker?: string = '';
    public desiredAgeRange?: string = '';

    public fileName: string[] = [];
    public url: string = '';
    public imageUrl: string[] = [];
    public fileObject: any = null;
    public hostUrl: string = hostImageUrl;

    public cancel() {
        this.$router.back();
    }

    public reset() {
        this.name = '';
        this.rankId = 0;
        this.isActive = false;
        this.occupation = '';
        this.place = '';
        this.birthplace = '';
        this.bloodTypeId = 5;
        this.blockClientUsers = [];
        this.fCastTypes = [];
        this.birthDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
        this.height = 0;
        this.weight = 0;
        this.bwhMeasurements = '';
        this.profile = '';
        this.favoriteFood = '';
        this.hateFood = '';
        this.hobby = '';
        this.specialty = '';
        this.skills = '';
        this.foreignLanguages = '';
        this.golf = '';
        this.alcohol = '';
        this.cigarette = '';
        this.askingPrice = 0;
        this.goodTalker = '';
        this.desiredAgeRange = '';

        if (this.cast) {
            this.name = this.cast.name;
            this.isActive = this.cast.is_active;
            this.occupation = this.cast.occupation;
            this.place = this.cast.place;
            this.birthplace = this.cast.birthplace;
            this.bloodTypeId = this.cast.blood_type_id;
            this.rankId = this.cast.rank_id;
            this.fCastTypes = this.cast.cast_types;
            this.blockClientUsers = this.cast.block_client_users;
            this.birthDate = this.cast.birth_date;
            this.height = this.cast.height;
            this.weight = this.cast.weight;
            this.bwhMeasurements = this.cast.bwh_measurements;
            this.profile = this.cast.profile;
            this.favoriteFood = this.cast.favorite_food;
            this.hateFood = this.cast.hate_food;
            this.hobby = this.cast.hobby;
            this.specialty = this.cast.specialty;
            this.skills = this.cast.skills;
            this.foreignLanguages = this.cast.foreign_languages;
            this.golf = this.cast.golf;
            this.alcohol = this.cast.alcohol;
            this.cigarette = this.cast.cigarette;
            this.askingPrice = this.cast.asking_price;
            this.goodTalker = this.cast.good_talker;
            this.desiredAgeRange = this.cast.desired_age_range;
        }
        this.$validator.reset();
    }

    public onFilePicked(event) {
        this.imageUrl = [];
        this.fileObject = [];
        this.fileName = [];
        const files = event.target.files;
        const fileUpload: any = this.$refs.fileInput;
        for (let i = 0; i < fileUpload.files.length; i++) {
            this.fileName.push(files[i].name);
            const previewImage = URL.createObjectURL(files[i]);
            this.imageUrl.push(previewImage);
        }
        this.fileObject = fileUpload;
    }

    public onPickFile() {
        const elem: any = this.$refs.fileInput;
        elem.click();
    }

    public removeImage(index) {
        this.imageUrl.splice(index, 1);
        this.fileName.splice(index, 1);
        const dt = new DataTransfer();
        const {files} = this.fileObject;
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (index !== i) { dt.items.add(file); }
            this.fileObject.files = dt.files;
        }
    }

    public async deleteImage(imageId) {
        if (confirm('Do you really want to delete image?')) {
            const response = await dispatchDeleteImageCast(this.$store, {
                castID: parseInt(this.$router.currentRoute.params.id, 10),
                imageID: imageId,
            });
            alert(response?.data[0]);
            await dispatchGetImagesCast(this.$store, {id: parseInt(this.$router.currentRoute.params.id, 10)});
        }
    }


    public async submit() {
        if (await this.$validator.validateAll()) {
            const updatedCast: ICastUpdate = {
                name: this.name,
                is_active: this.isActive,
                occupation: this.occupation,
                blood_type_id: this.bloodTypeId,
                rank_id: this.rankId,
                birth_date: this.birthDate,
                height: this.height,
                bwh_measurements: this.bwhMeasurements,
                profile: this.profile,
                favorite_food: this.favoriteFood,
                hate_food: this.hateFood,
                hobby: this.hobby,
                specialty: this.specialty,
                skills: this.skills,
                foreign_languages: this.foreignLanguages,
                golf: this.golf,
                alcohol: this.alcohol,
                cigarette: this.cigarette,
                place: this.place,
                birthplace: this.birthplace,
                asking_price: this.askingPrice,
                good_talker: this.goodTalker,
                desired_age_range: this.desiredAgeRange,
                cast_types: this.fCastTypes,
                block_client_users: this.blockClientUsers,
            };
            const response = await dispatchUpdateCast(this.$store, {
                id: parseInt(this.$router.currentRoute.params.id, 10),
                cast: updatedCast,
            });
            if (response?.status === 200) {
                if (this.fileObject === null) {
                    await this.$router.push('/main/casts/all');
                } else {
                    const formData = new FormData();
                    for (let i = 0; i < this.fileObject.files.length; i++) {
                        const file = this.fileObject.files[i];
                        formData.append('files', file);
                        formData.append('sort_number', '' + i);
                    }
                    const upload = await dispatchUploadImagesCast(this.$store,
                        {
                            files: formData,
                            castId: parseInt(this.$router.currentRoute.params.id, 10),
                        },
                    );
                    if (upload?.status === 200) {
                        await this.$router.push('/main/casts/all');
                    }
                }
            }
        }
    }

    public async mounted() {
        await dispatchGetBloods(this.$store);
        await dispatchGetCastTypes(this.$store);
        await dispatchGetRanks(this.$store);
        await dispatchGetCasts(this.$store);
        await dispatchGetCastType(this.$store, {id: parseInt(this.$router.currentRoute.params.id, 10)});
        await dispatchGetImagesCast(this.$store, {id: parseInt(this.$router.currentRoute.params.id, 10)});
        await dispatchGetClientUsers(this.$store);
        this.reset();
    }

    get imagesCast() {
        return readImagesCast(this.$store);
    }

    get cast() {
        return readCast(this.$store)(+this.$router.currentRoute.params.id);
    }

    get castTypes() {
        return readCastTypes(this.$store);
    }

    get ranks() {
        return readRanks(this.$store);
    }

    get bloods() {
        return readBloods(this.$store);
    }

    get castType() {
        const types = readCastType(this.$store);
        const arrType: number[] = [];
        types.forEach((element) => {
                arrType.push(element.cast_type_id);
            },
        );
        return arrType;
    }

    get clientUsers() {
        return readClientUsers(this.$store);
    }

    get age() {
        const date = new Date(this.birthDate ?? '');
        const current = new Date();
        return (current.getFullYear() - date.getFullYear()) + '歳';
    }
}
