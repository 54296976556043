import {
    IBloodTypes,
} from '@/interfaces';
import { BloodState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setBloods(state: BloodState, payload: IBloodTypes[]) {
      state.bloods = payload;
    },
};

const { commit } = getStoreAccessors<BloodState, State>('');

export const commitSetBloods = commit(mutations.setBloods);

