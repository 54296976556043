import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { BloodState } from './state';

const defaultState: BloodState = {
  bloods: [],
};

export const bloodModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
