import { RankState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    ranks: (state: RankState) => state.ranks,
    oneRank: (state: RankState) => (rankId: number) => {
        const filteredRanks = state.ranks.filter((rank) => rank.id === rankId);
        if (filteredRanks.length > 0) {
            return { ...filteredRanks[0] };
        }
    },
};

const { read } = getStoreAccessors<RankState, State>('');

export const readOneRank = read(getters.oneRank);
export const readRanks = read(getters.ranks);
