import { api } from '@/api';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { CastTypeState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import {
    commitSetCastTypes,
    commitSetCastType,
} from './mutations';

type MainContext = ActionContext<CastTypeState, State>;

export const actions = {
    async actionGetCastTypes(context: MainContext) {
        try {
            const response = await api.getCastTypes();
            if (response) {
                commitSetCastTypes(context, response.data);
            }
        } catch (error) {
            return error;
        }
    },
    async actionGetCastType(context: MainContext, payload: { id: number}) {
        try {
            const response = await api.getCastType(context.rootState.main.token, payload.id);
            if (response) {
                commitSetCastType(context, response.data);
            }
        } catch (error) {
            return error;
        }
    },
};

const { dispatch } = getStoreAccessors<CastTypeState, State>('');

export const dispatchGetCastTypes = dispatch(actions.actionGetCastTypes);
export const dispatchGetCastType = dispatch(actions.actionGetCastType);

