import axios from 'axios';
import { apiUrl } from '@/env';
import {
  IUserProfile,
  IUserProfileUpdate,
  IUserProfileCreate,
  IClientUserProfile,
  IClientUserProfileUpdate,
  IClientUserProfileCreate,
  ICastProfile,
  IBloodTypes,
  ICastCreate,
  ICastTypes,
  IRank,
  ICastUpdate,
  IUploadImagesCastCreate,
} from './interfaces';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

function authHeadersUpload(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      ContentType: 'multipart/form-data',
    },
  };
}
export const api = {
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  async getMe(token: string) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me`, authHeaders(token));
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(`${apiUrl}/api/v1/users/me`, data, authHeaders(token));
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/`, authHeaders(token));
  },
  async updateUser(token: string, userId: number, data: IUserProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/users/${userId}`, data, authHeaders(token));
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/users/`, data, authHeaders(token));
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/api/v1/reset-password/`, {
      new_password: password,
      token,
    });
  },

  // Client
  async getClientUsers(token: string) {
    return axios.get<IClientUserProfile[]>(`${apiUrl}/api/v1/client_users/`, authHeaders(token));
  },
  async updateClientUser(token: string, userId: number, data: IClientUserProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/client_users/${userId}`, data, authHeaders(token));
  },
  async createClientUser(token: string, data: IClientUserProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/client_users/`, data, authHeaders(token));
  },
  async getCasts(token: string) {
    return axios.get<ICastProfile[]>(`${apiUrl}/api/v1/casts/`, authHeaders(token));
  },
  async getBloodType() {
    return axios.get<IBloodTypes[]>(`${apiUrl}/api/v1/blood_types/`);
  },
  async getCastTypes() {
    return axios.get<ICastTypes[]>(`${apiUrl}/api/v1/cast_types/`);
  },
  async getRank(token: string) {
    return axios.get<IRank[]>(`${apiUrl}/api/v1/ranks/`, authHeaders(token));
  },
  async createCast(token: string, data: ICastCreate) {
    return axios.post(`${apiUrl}/api/v1/casts/create`, data, authHeaders(token));
  },
  async updateCast(token: string, castID: number, data: ICastUpdate) {
    return axios.put(`${apiUrl}/api/v1/casts/update/${castID}`, data, authHeaders(token));
  },
  async deleteCast(token: string, castID: number) {
    return axios.get(`${apiUrl}/api/v1/casts/delete/${castID}`, authHeaders(token));
  },
  async getCastType(token: string, castID: number) {
    return axios.get(`${apiUrl}/api/v1/casts/type/${castID}`, authHeaders(token));
  },
  async uploadImagesCast(token: string, data: IUploadImagesCastCreate, castID: number) {
    return axios.post(`${apiUrl}/api/v1/casts/upload/image/${castID}`, data, authHeadersUpload(token));
  },
  async getImagesCast(token: string, castID: number) {
    return axios.get(`${apiUrl}/api/v1/casts/image/all/${castID}`, authHeaders(token));
  },
  async deleteImage(token: string, castID: number, imageID: number) {
    return axios.delete(`${apiUrl}/api/v1/casts/delete/image/${castID}/${imageID}`, authHeaders(token));
  },
};
