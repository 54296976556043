import {
    ICastTypes,
    ICastType,
} from '@/interfaces';
import { CastTypeState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setCastTypes(state: CastTypeState, payload: ICastTypes[]) {
      state.castTypes = payload;
    },
    setCastType(sate: CastTypeState, payload: ICastType[]) {
        sate.castType = payload;
    },
};

const { commit } = getStoreAccessors<CastTypeState, State>('');

export const commitSetCastTypes = commit(mutations.setCastTypes);
export const commitSetCastType = commit(mutations.setCastType);
