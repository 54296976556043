













































































































import { Component, Vue } from 'vue-property-decorator';
import {
    IClientUserProfile,
    IClientUserProfileUpdate,
    IRank,
} from '@/interfaces';
import { dispatchGetClientUsers, dispatchUpdateClientUser } from '@/store/client/actions';
import { dispatchGetRanks } from '@/store/rank/actions';
import { readClientOneUser } from '@/store/client/getters';
import { readRanks } from '@/store/rank/getters';

@Component
export default class EditUser extends Vue {
  public valid = true;
  public name: string = '';
  public email: string = '';
  public rank: IRank = { id: 0, name: '', sort_number: 0};
  public isActive: boolean = true;
  public setPassword = false;
  public password1: string = '';
  public password2: string = '';

  public ranks: IRank[] = [{ id: 0, name: '', sort_number: 0}];

  public async mounted() {
    await dispatchGetClientUsers(this.$store);
    await dispatchGetRanks(this.$store);
    this.getRanks();
    this.reset();
  }

  public getRanks() {
      this.ranks = readRanks(this.$store);
  }

  public reset() {
    this.setPassword = false;
    this.password1 = '';
    this.password2 = '';
    this.$validator.reset();
    if (this.user) {
      this.name = this.user.name;
      this.email = this.user.email;
      this.rank = this.ranks.find((r) => r.id === this.user?.rank_id) ?? { id: 0, name: '', sort_number: 0};
      this.isActive = this.user.is_active;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedProfile: IClientUserProfileUpdate = {};
      if (this.name) {
        updatedProfile.name = this.name;
      }
      if (this.email) {
        updatedProfile.email = this.email;
      }
      if (this.rank.id > 0) {
        updatedProfile.rank_id = this.rank.id;
      }
      updatedProfile.is_active = this.isActive;
      if (this.setPassword) {
        updatedProfile.password = this.password1;
      }
      await dispatchUpdateClientUser(this.$store, { id: this.user!.id, user: updatedProfile });
      this.$router.push('/main/client/users');
    }
  }

  get user() {
    return readClientOneUser(this.$store)(+this.$router.currentRoute.params.id);
  }
}
