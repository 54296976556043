import { api } from '@/api';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { BloodState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import {
    commitSetBloods,
} from './mutations';

type MainContext = ActionContext<BloodState, State>;

export const actions = {
    async actionGetBlood(context: MainContext) {
        try {
            const response = await api.getBloodType();
            if (response) {
                commitSetBloods(context, response.data);
            }
        } catch (error) {
            return error;
        }
    },
};

const { dispatch } = getStoreAccessors<BloodState, State>('');

export const dispatchGetBloods = dispatch(actions.actionGetBlood);
