import { IRank } from '@/interfaces';
import { RankState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setRanks(state: RankState, payload: IRank[]) {
        state.ranks = payload;
    },
    setRank(state: RankState, payload: IRank) {
        const ranks = state.ranks.filter((rank: IRank) => rank.id !== payload.id);
        ranks.push(payload);
        state.ranks = ranks;
    },
};

const { commit } = getStoreAccessors<RankState, State>('');

export const commitSetRank = commit(mutations.setRank);
export const commitSetRanks = commit(mutations.setRanks);
