import { CastState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    casts: ( state: CastState) => state.cast,
    cast: (state: CastState) => (castId: number) => {
        const filterCast = state.cast.filter((cast) => cast.id === castId);
        if (filterCast.length > 0) {
            return { ...filterCast[0] };
        }
    },
};

const { read } = getStoreAccessors<CastState, State>('');

export const readCasts = read(getters.casts);
export const readCast = read(getters.cast);
