import { CastTypeState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    castTypes: ( state: CastTypeState) => state.castTypes,
    castType: (state: CastTypeState) => state.castType,
};

const { read } = getStoreAccessors<CastTypeState, State>('');

export const readCastTypes = read(getters.castTypes);
export const readCastType = read(getters.castType);
