import { BloodState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    bloods: ( state: BloodState) => state.bloods,
};

const { read } = getStoreAccessors<BloodState, State>('');

export const readBloods = read(getters.bloods);

