import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';

import { mainModule } from './main';
import { State } from './state';
import { adminModule } from './admin';
import { castModule } from '@/store/cast';
import { bloodModule } from '@/store/blood';
import { rankModule } from '@/store/rank';
import { castTypeModule } from '@/store/castType';
import {uploadImageCastModule} from '@/store/imagesCast';
import { clientModule } from './client';

Vue.use(Vuex);

const storeOptions: StoreOptions<State> = {
  modules: {
    main: mainModule,
    admin: adminModule,
    cast: castModule,
    blood: bloodModule,
    rank: rankModule,
    client: clientModule,
    castType: castTypeModule,
    uploadImagesCast: uploadImageCastModule,
  },
};

export const store = new Vuex.Store<State>(storeOptions);

export default store;
