








































import { readCasts } from '@/store/cast/getters';
import { dispatchGetCasts } from '@/store/cast/actions';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Casts extends Vue {
    public headers = [
        {
            text: 'Id',
            sortable: true,
            value: 'id',
            align: 'left',
        },
        {
            text: 'Name',
            sortable: true,
            value: 'name',
            align: 'left',
        },
        {
            text: 'Rank',
            sortable: true,
            value: 'rank.name',
            align: 'left',
        },
        {
            text: 'Is active',
            sortable: true,
            value: 'is_active',
            align: 'left',
        },
        {
            text: 'Birth Date',
            sortable: true,
            value: 'birth_date',
            align: 'left',
        },
        {
            text: 'height',
            sortable: true,
            value: 'height',
            align: 'left',
        },
        {
            text: 'bwh measurements',
            sortable: true,
            value: 'bwh_measurements',
            align: 'left',
        },
        {
          text: 'Action',
          value: 'id',
        },
    ];
    get casts() {
        return readCasts(this.$store);
    }
    public async mounted() {
        await dispatchGetCasts(this.$store);
    }
}
