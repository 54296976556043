import { api } from '@/api';
import { ActionContext } from 'vuex';
import { IClientUserProfileCreate, IClientUserProfileUpdate } from '@/interfaces';
import { State } from '../state';
import { ClientState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetClientUsers, commitSetClientUser } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';

type MainContext = ActionContext<ClientState, State>;

export const actions = {
    async actionGetClientUsers(context: MainContext) {
        try {
            const response = await api.getClientUsers(context.rootState.main.token);
            if (response) {
                commitSetClientUsers(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateClientUser(context: MainContext, payload: { id: number, user: IClientUserProfileUpdate }) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateClientUser(context.rootState.main.token, payload.id, payload.user),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetClientUser(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'User successfully updated', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateClientUser(context: MainContext, payload: IClientUserProfileCreate) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createClientUser(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetClientUser(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'User successfully created', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
};

const { dispatch } = getStoreAccessors<ClientState, State>('');

export const dispatchCreateClientUser = dispatch(actions.actionCreateClientUser);
export const dispatchGetClientUsers = dispatch(actions.actionGetClientUsers);
export const dispatchUpdateClientUser = dispatch(actions.actionUpdateClientUser);
