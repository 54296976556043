import {
    ICastProfile,
} from '@/interfaces';
import { CastState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setCasts(state: CastState, payload: ICastProfile[]) {
      state.cast = payload;
    },
};

const { commit } = getStoreAccessors<CastState, State>('');

export const commitSetCasts = commit(mutations.setCasts);
