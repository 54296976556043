import {api} from '@/api';
import {ActionContext} from 'vuex';
import {State} from '../state';
import {ImageCastState} from './state';
import {getStoreAccessors} from 'typesafe-vuex';
import {commitSetImagesCast} from '@/store/imagesCast/mutations';

type MainContext = ActionContext<ImageCastState, State>;

export const actions = {
    async uploadImagesCast(context: MainContext, payload: { castId: number, files: any }) {
        try {
            const response = await api.uploadImagesCast(context.rootState.main.token, payload.files, payload.castId);
            if (response) {
                return response;
            }
        } catch (error) {
            return error;
        }
    },
    async actionGetImagesCast(context: MainContext, payload: { id: number}) {
        try {
            const response = await api.getImagesCast(context.rootState.main.token, payload.id);
            if (response) {
                commitSetImagesCast(context, response.data);
            }
        } catch (error) {
            return error;
        }
    },
    async actionDeleteImage(context: MainContext, payload: { castID: number, imageID: number}) {
        try {
            const response = await api.deleteImage(context.rootState.main.token, payload.castID, payload.imageID);
            return response;
        } catch (error) {
            return error;
        }
    },
};

const {dispatch} = getStoreAccessors<ImageCastState, State>('');

export const dispatchUploadImagesCast = dispatch(actions.uploadImagesCast);
export const dispatchGetImagesCast = dispatch(actions.actionGetImagesCast);
export const dispatchDeleteImageCast = dispatch(actions.actionDeleteImage);
