




























import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { IClientUserProfile, IRank } from '@/interfaces';
import { readClientUsers } from '@/store/client/getters';
import { readRanks } from '@/store/rank/getters';
import { dispatchGetClientUsers } from '@/store/client/actions';
import { dispatchGetRanks } from '@/store/rank/actions';

@Component
export default class ClientUsers extends Vue {
  public headers = [
    {
      text: 'Name',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'Email',
      sortable: true,
      value: 'email',
      align: 'left',
    },
    {
      text: 'Rank',
      sortable: true,
      value: 'rank_id',
      align: 'left',
    },
    {
      text: 'Is Active',
      sortable: true,
      value: 'isActive',
      align: 'left',
    },
    {
      text: 'Actions',
      value: 'id',
    },
  ];

  // data
  public dRanks: IRank[] = [];


  get users() {
    return readClientUsers(this.$store);
  }

  get ranks() {
    if (this.dRanks.length === 0) {
      this.dRanks = readRanks(this.$store);
    }

    return this.dRanks;
  }

  public rankText(rankId: number) {
    const rank: IRank|undefined = this.ranks.find((r) => r.id === rankId);

    return rank?.name;
  }

  public async mounted() {
    await dispatchGetClientUsers(this.$store);
    await dispatchGetRanks(this.$store);
  }
}
