



































































































































































































































import {Component, Vue} from 'vue-property-decorator';
import {readCast} from '@/store/cast/getters';
import {readCastType, readCastTypes} from '@/store/castType/getters';
import {
    dispatchGetCasts,
    dispatchDeleteCast,
} from '@/store/cast/actions';
import {hostImageUrl} from '@/env';

@Component
export default class CastDetail extends Vue {
    public hostUrl: string = hostImageUrl;

    public async mounted() {
        await dispatchGetCasts(this.$store);
    }

    get cast() {
        return readCast(this.$store)(+this.$router.currentRoute.params.id);
    }

    public cancel() {
        this.$router.back();
    }

    public async remove() {
        if (confirm('Do you really want to delete?')) {
            const response = await dispatchDeleteCast(this.$store, {
                id: parseInt(this.$router.currentRoute.params.id, 10),
            });
            if (response?.status === 200) {
                this.$router.push('/main/casts/all');
            }
        }
    }
}
