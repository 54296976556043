import { ImageCastState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    imagesCast: ( state: ImageCastState) => state.imagesCast,
};

const { read } = getStoreAccessors<ImageCastState, State>('');

export const readImagesCast = read(getters.imagesCast);
